<template>
  <div class="bbs-sidebar-box" v-if="subjectList.length">
    <div class="side-question">
      <div class="side-tit">
        <div class="side-tit-left">{{ $t("bbs.quesition") }}</div>
        <!-- 问题 -->
      </div>
      <div class="side-question-list">
        <div
          class="side-question-item"
          v-for="item in subjectList"
          :key="item.subjectId"
          @click="subjectJump(item)"
        >
          <p class="q">{{ item.title }}</p>
          <p class="a">{{ $t("bbs.nth_answer", [item.answers]) }}</p>
          <!-- 个回答 -->
        </div>
        <a-empty v-if="!subjectList.length" />
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { getSubjectList } from "@/api/bbs";
import checkJump from "@/views/bbs/utils/checkJump";
export default {
  name: "sideQuestion",
  props: {
    newWindow: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const state = reactive({
      subjectList: [],
    });

    getSubjectList({
      status: 1,
      pageSize: 5,
      page: 1,
      order: 1,
      subjectType: 2,
    }).then((res) => {
      state.subjectList = (res.data.list || []).slice(0, 5);
    });

    const subjectJump = (item) => {
      checkJump(props.newWindow, item);
    };

    return {
      ...toRefs(state),
      subjectJump,
    };
  },
};
</script>

<style lang="less" scoped>
.side-tit {
  padding: 15px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  line-height: 24px;
  .mixinFlex(space-between);
  &-left {
    font-size: 16px;
    color: #202020;
  }
}
.side-question {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.15);
  &-list {
    padding: 15px 20px;
  }
  &-item {
    margin-bottom: 8px;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }
    .q {
      font-size: 14px;
      color: #666;
      .mixinEllipsis(22px);
      margin: 0;
      transition: color 0.3s;
    }
    .a {
      font-size: 12px;
      color: #999;
      line-height: 20px;
      margin: 0;
    }
    &:hover {
      .q {
        color: @color-theme;
      }
    }
  }
}
</style>
